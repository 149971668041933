
<template>
  <div class="row">
    <div class="col-12">
      <h4 class="mb-3">Subscriptions</h4>
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-12 col-md-8">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex mr-2 align-items-center">
                  <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                </label>
                <b-dropdown variant="light" left>
                  <template slot="button-content">
                      <i class="mdi mdi-filter-variant"></i>
                  </template>
                  <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="">
                <label class="d-block">
                  <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
                ref="selectableTable" :items="pageData.data" :fields="columns" responsive="sm">
              <template v-slot:cell(check)="data">
                <div class="form-check text-center m-auto">
                  <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input" :id="`tableSRadio${data.item.id}`" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`">{{data.item.id}}</label>
                </div>
              </template>
              <template v-slot:cell(plan)="data">
                <div> 
                  <p class="mb-1">{{data.item.plan.title}} plan</p>
                  <p class="mb-0">Interval: {{data.item.plan.billing_period}}</p>
                </div>
              </template>
              <template v-slot:cell(current_period_start)="data">
                <span> {{data.item.current_period_start | date_time(1)}} </span>
              </template>
              <template v-slot:cell(current_period_end)="data">
                <span> {{data.item.current_period_end | date_time(1)}} </span>
              </template>
              <template v-slot:cell(status)="data">
                <span v-html="statusBadge(data.item.status)"></span>
              </template>
              <template v-slot:cell(action)="data">
                <div>
                  <router-link :to="`/subscriptions/${data.item.id}`" class="mr-2">
                    view</router-link>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="paging_simple_numbers float-right">
                <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                  :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IsLoading from "@/components/IsLoading.vue"
import _ from 'lodash';

export default {
  components:{
    IsLoading
  },
  name: "subscription-list",
  data() {
    return {
      isLoading: false,
      searchQuery: "",
      pageData: {
        data:[]
      },
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: ""
          },
          {
            key: "plan",
            label: "Plan",
          },
          {
            key: "current_period_start",
            label: "Period start",
          },
          {
            key: "current_period_end",
            label: "Period end",
          },
          {
            key: "status",
            label: "Status",
          },
          "action"
      ]
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/subscriptions?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
  },
  created(){
    this.fetchItems()
  },
}

</script>

